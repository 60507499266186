export const FranchiseUserConfig = {
  api: {
    // get
    index: '/dashboard/franchises/',
    // post
    create: '/dashboard/franchises/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/franchises/${id}/`
    },
  },
  events: {
    name: 'franchise',
    // refresh-index-data
    refresh: `rid-franchise`,
    // slideover-right
    sorId: 'franchise',
    sorOpen: 'sor-open-franchise',
    sorClose: 'sor-close-franchise',
    sorToggle: 'sor-toggle-franchise',
    // editing-data
    editingData: 'edit-franchise-data',
    // viewing-data
    viewingData: 'view-franchise-data',
  },
}
