<template>
  <base-layout>
    <UserAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :user-id="ae.userId"
      :user-data="ae.userData"
    />
    <content-section :spacing="false">
      <div class="content-spacing px-4">
        <title-plus title="Franchise Users" @plus="onOpenES" class="mb-10" />

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent px-4">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewOrganizationUserProfile',
                  params: { id: item.id },
                }"
                target="_blank"
              >
                #{{ item.id | onlyLastFive }}
              </router-link>
            </fsTableRowItem>
            <fsTableRowItem>
              <div
                @click="view(item)"
                class="font-semibold text-blue-700 cursor-pointer"
              >
                <router-link
                  class="font-bold text-blue-600 capitalize"
                  :to="{
                    name: 'ViewOrganizationUserProfile',
                    params: { id: item.id },
                  }"
                  target="_blank"
                >
                  {{ item.full_name }}
                </router-link>
              </div>
            </fsTableRowItem>
            <fsTableRowItem>{{ item.organization_name }}</fsTableRowItem>
            <fsTableRowItem>{{ item.phone_number }}</fsTableRowItem>
            <fsTableRowItem>{{ item.email }}</fsTableRowItem>
            <fsTableRowItem>{{
              item.role ? item.role.role_name : '--'
            }}</fsTableRowItem>
            <fsTableRowItem
              ><div v-if="item.last_login">
                {{ item.last_login | friendlyDateTime }}
              </div>
              <div v-else>-</div></fsTableRowItem
            >
            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="onOpenES(item.id)" />
                <UserIndexActions
                  :primary-key="item.id"
                  :data="item"
                  @accountStatusUpdated="getIndexData(getQueryString)"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>
        <!-- fs-table-footer -->
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
        <!-- fs-table-footer -->
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoSearch from '@/components/ui/OtoSearch'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import UserAddEdit from './UserAddEdit'
import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { FranchiseUserConfig } from '@/config/FranchiseUserConfig'
// import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
import UserIndexActions from './UserIndexActions.vue'
export default {
  name: 'Franchise Users',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    ExportAsDropdown,
    OtoSearch,
    OtoEditIcon,
    UserAddEdit,
    UserIndexActions,
  },
  data() {
    return {
      indexDataEndpoint: FranchiseUserConfig.api.index,
      tableHeaders: [
        {
          name: 'ID',
          width: '8%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'Full Name',
          width: '13%',
          field: 'full_name',
          sort: true,
          order: '',
        },
        {
          name: 'Organization Name',
          width: '13%',
          field: 'organization_name',
          sort: true,
          order: '',
        },
        {
          name: 'Phone Number',
          width: '15%',
          field: 'email',
          sort: true,
          order: '',
        },
        {
          name: 'Organization Email',
          width: '15%',
          field: 'email',
          sort: true,
          order: '',
        },
        {
          name: 'Role',
          width: '10%',
          field: 'role',
          sort: null,
          order: '',
        },
        {
          name: 'Last Login',
          width: '15%',
          field: 'last_login',
          sort: null,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
      // AddEdit (ae)
      ae: {
        // EdgeStack component
        esId: 'user-add-edit',
        // XStepper component
        stepperMode: 'free',
        stepperStep: 1,
        // Data
        data: {},
        userId: null,
        userData: null,
      },
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(FranchiseUserConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    // EdgeStack component
    async onOpenES(userId = null) {
      // if userId provided, open it in free (aka edit) mode, inject user data as well
      // otherwise, open it in strict (aka add mode). In both case, inject required data,
      // but user data is required in edit (free) mode.
      if (userId) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )
        // fetch user data
        await this.$http
          .get(this.$config.orgUser.api.single(userId))
          .then((res) => {
            console.log('res-data', res.data)
            this.ae.stepperMode = 'free'
            this.ae.userId = res.data.id
            this.ae.userData = res.data
            this.$edgeStack.open(this.ae.esId)
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        // this.$xStepper.navigate(this.ae.esId).to(4)
        this.ae.stepperMode = 'strict'
        this.ae.userId = null
        this.ae.userData = null
        this.$edgeStack.open(this.ae.esId)
      }
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return FranchiseUserConfig.api.update(id)
    },
  },
}
</script>

<style></style>
